import type { AppProviderProps, CommonRouteProps, LeftRouteProps } from '..'

import { NavLink } from 'react-router-dom'

import { Drawer, IconButton, Divider, Stack, Box, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material'
import { ChevronRight, Close, Logout } from '@mui/icons-material'

import { useLangage, useSecurity, useUi } from '..'
import { DrawerHeader, LocaleSelector, UserMenu } from '.'

import { SearchField } from './Search'

type DrawerLeftProps = {
    routes: AppProviderProps['routes']
}
export const DrawerLeft = ({ routes }: DrawerLeftProps) => {
    const { trad } = useLangage()
    const { logout } = useSecurity()
    const { open, drawerWidth, toggleOpen, setTheme } = useUi()
    return (
        <Drawer
            sx={theme => ({
                width: '100vw',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '100vw',
                    boxSizing: 'border-box',
                },
                [theme.breakpoints.up('md')]: {
                    width: `${drawerWidth}px !important`,
                    '& .MuiDrawer-paper': {
                        width: `${drawerWidth}px !important`,
                        boxSizing: 'border-box',
                    },
                },
            })}
            variant="persistent"
            anchor="left"
            open={open}
            onClick={toggleOpen}
        >
            <Stack sx={{ height: '100vh', overflow: 'hidden' }}>
                <DrawerHeader>
                    <IconButton onClick={e => { e.stopPropagation(); toggleOpen() }}>
                        {open ? <Close /> : <ChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <UserMenu />
                <Box p={1} onClick={e => e.stopPropagation()}>
                    <SearchField />
                </Box>
                <Divider />
                <Stack direction={'column'} flexGrow={1} sx={{ overflowX: 'auto' }}>
                    <List sx={{
                        '& hr+hr': { display: 'none' },
                        '& hr:first-child': { display: 'none' },
                        '& hr:last-child': { display: 'none' },
                    }}>
                        {(routes.filter(r => typeof r === 'string' || r.isLeft === true) as (CommonRouteProps & LeftRouteProps)[])
                            // .map(({ trad: tradValue, url, icon: Icon }) =>
                            //     <ListItemButton key={url} component={NavLink} to={url}>
                            //         <ListItemIcon>
                            //             {Icon && <Icon />}
                            //         </ListItemIcon>
                            //         <ListItemText primary={trad[tradValue]} />
                            //     </ListItemButton>)}
                            .map((route, key) =>
                                typeof route === 'string'
                                    ? <Divider key={key} />
                                    : <ListItemButton key={route.url} component={NavLink} to={route.url}>
                                        <ListItemIcon>
                                            {route.icon && <route.icon />}
                                        </ListItemIcon>
                                        <ListItemText primary={trad[route.trad]} />
                                    </ListItemButton>)}
                    </List>
                </Stack>
                <Divider />
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
                    <Stack>
                        <Box>
                            <LocaleSelector />
                        </Box>
                    </Stack>
                    <Stack>
                        <Box>
                            <IconButton onClick={() => setTheme((prev => prev === 'dark' ? 'light' : 'dark'), undefined)}>
                                toggle
                            </IconButton>
                        </Box>
                    </Stack>
                    <Stack>
                        <Box>
                            <IconButton onClick={logout}>
                                <Logout />
                            </IconButton>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </Drawer>
    )
}