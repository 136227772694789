import type { AppProviderProps } from '..'

import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, CssBaseline, Toolbar, IconButton, Typography, Link } from '@mui/material'
import { Menu } from '@mui/icons-material'

import { useLangage, useRoutage, useUi } from '..'

import { DrawerHeader, DrawerLeft, Footer, Header, Main, UserToolbar } from '.'

import Logo from '../../assets/101_square.png'
import { SearchDrawer } from './Search'

type UIProps = {
    // routes: AppProviderProps['routes']
} & React.PropsWithChildren
export const UI = ({ children }: UIProps) => {
    const { open, drawerWidth, toggleOpen, overflowY, zIndex } = useUi()
    const { trad } = useLangage()
    const { routes } = useRoutage()

    return (
        <Box sx={{
            display: 'flex',
            width: '100vw !important',
            height: '100vh',
            overflowY: overflowY ? 'auto' : 'hidden'
        }}>
            <CssBaseline />
            <Header position="fixed" open={open} drawerWidth={drawerWidth} sx={{ zIndex }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <Menu />
                    </IconButton>
                    <Link component={NavLink} to="/">
                        <Typography variant="h6" color="white">
                            <Box display={'flex'} whiteSpace="nowrap" gap={1}>
                                <img src={Logo} style={{ height: '1.5em' }} alt="logo" />
                                {trad.site_name}
                            </Box>
                        </Typography>
                    </Link>

                    <Box flexGrow={1} />

                    <UserToolbar />

                </Toolbar>
            </Header>
            <DrawerLeft routes={routes} />
            <Main open={open} drawerWidth={drawerWidth}>
                <DrawerHeader />
                {children}
            </Main>
            <Footer />
            <SearchDrawer />

        </Box>
    )
}