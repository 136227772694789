import { lazy } from "react";
import { BrowserRouter } from "react-router-dom";

import { Home, MenuBook, Person } from "@mui/icons-material";

import { AppProvider } from "../AppProvider";

const LandingPage = lazy(() => import('./Components/LandingPage'))
const LandingFormation = lazy(() => import('./Components/Formation'))
const FormationList = lazy(() => import('./Components/FormationList'))

const CoursViewer = lazy(() => import('./Components/Document/Cours'))
const MonographViewer = lazy(() => import('./Components/Document/Monograph'))
const ProcedureViewer = lazy(() => import('./Components/Document/Procedure'))

const Quizz = lazy(() => import('./Components/Quizz'))


const AppClient = () => {
    return (
        <BrowserRouter>
            <AppProvider routes={[
                { trad: 'home', url: '/', component: LandingPage, icon: Home, isLeft: true },

                { trad: 'myFormations', url: '/formations', component: FormationList, icon: MenuBook },
                { url: '/formations/:id', component: LandingFormation },

                { url: '/documents/1/:id', component: ProcedureViewer },
                { url: '/documents/2/:id', component: CoursViewer },
                { url: '/documents/3/:id', component: MonographViewer },
                { url: '/documents/5/:id', component: CoursViewer },

                { url: '/quizz/:formationId/:typeContentId', component: Quizz },
                { url: '/quizz/:quizzId', component: Quizz },
            ]}>
            </AppProvider>
        </BrowserRouter>
    )
}

export default AppClient;